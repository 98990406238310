import {AsYouType, parsePhoneNumberFromString} from "libphonenumber-js";

export default {
    computed: {
        phoneRules() {
            return [
                v => !!v || this.$t('errors.fillIsRequired'),
                input => {
                    if (input) {
                        let phone = input.replaceAll(' ', '');
                        const phoneNumber = parsePhoneNumberFromString(phone);
                        if (phoneNumber) {
                            if (!phoneNumber.isValid()) {
                                return this.$t('Notify.signupComponent.phoneNumberError');
                            } else {
                                return true;
                            }
                        } else {
                            return this.$t('Notify.signupComponent.phoneRequired');
                        }
                    }
                    return false
                }
            ]
        },
    },
    watch: {
        phone() {
            // let p = this.phone;

            // if (this.phone.startsWith('00')) {
            //     this.phone = this.phone.substr(2);
            //     this.phone = '+' + this.phone;
            // }
            //
            // if (this.phone.startsWith('098')) {
            //     this.phone = this.phone.substr(1);
            //     this.phone = '+' + this.phone;
            // }
            //
            // if (this.phone.startsWith('09')) {
            //     this.phone = this.phone.substr(1);
            //     this.phone = '+98' + this.phone;
            // }

            // if (this.phone.startsWith(0)) {
            //   this.phone = this.phone.replace(0, '');
            // }
            //
            // let x = new AsYouType().input(this.selectedCountry.code + this.phone);
            // x = x.replace(this.selectedCountry.code, '');
            // this.phone = x;


            if (this.phone.startsWith('00')) {
                this.phone = this.phone.substr(2);
                this.phone = '+' + this.phone;
            }

            if (this.phone.startsWith('098')) {
                this.phone = this.phone.substr(1);
                this.phone = '+' + this.phone;
            }

            if (this.phone.startsWith('09')) {
                this.phone = this.phone.substr(1);
                this.phone = '+98' + this.phone;
            }

            this.phone = new AsYouType().input(this.phone);
        }
    },
}